export const canI = (auth) => {
  var iCan = true;
  if (auth == 'None' || auth == 'N/A') {
    iCan = false;
  }
  return iCan;
}

export const canIAll = (auth) => {
  var iCan = false;
  if (auth == 'All') {
    iCan = true;
  }
  return iCan;
}

export const canICompany = (auth) => {
  var iCanCompany = false;
  if (auth == 'Own') {
    iCanCompany = true;
  }
  return iCanCompany;
}

export const canIProject = (auth) => {
  var iCanProject = false;
  if (auth == 'Project') {
    iCanProject = true;
  }
  return iCanProject;
}
