import React from "react";
import * as Icon from "react-feather";
import { FormattedMessage } from "react-intl";
import customLabel from "../../src/assets/customArray/label.json";
import { canI } from "../authFunctions/functions";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));
const auth = JSON.parse(localStorage.getItem("auth"));
const companies = JSON.parse(localStorage.getItem("companies"));
const jobs = JSON.parse(localStorage.getItem("jobs"));

const horizontalMenuConfig = [
  auth != null &&
  !canI(auth["WORLD WIDE SITE"].EditOpzione) &&
  !canI(auth["WORLD WIDE SITE"].ReadOpzione) &&
  !canI(auth["DASHBOARD"].EditOpzione) &&
  !canI(auth["DASHBOARD"].ReadOpzione)
    ? {}
    : {
        id: "homeDropdown",
        title: <FormattedMessage id="Home" />,
        type: "dropdown",
        icon: <Icon.Home size={15} />,
        children: [
          auth != null &&
          !canI(auth["WORLD WIDE SITE"].EditOpzione) &&
          !canI(auth["WORLD WIDE SITE"].ReadOpzione)
            ? {}
            : {
                id: "worldwide",
                title: <FormattedMessage id="Worldwide sites" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/map",
              },
          auth != null &&
          !canI(auth["DASHBOARD"].EditOpzione) &&
          !canI(auth["DASHBOARD"].ReadOpzione)
            ? {}
            : {
                id: "dashBoard",
                title: <FormattedMessage id="Dashboard" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/dashboard",
              },
        ],
      },
  auth != null &&
  !canI(auth["USERS LIST (all employees?)"].EditOpzione) &&
  !canI(auth["USERS LIST (all employees?)"].ReadOpzione) &&
  !canI(auth["USERS ROLE"].EditOpzione) &&
  !canI(auth["USERS ROLE"].ReadOpzione) &&
  !canI(auth["AUTHORIZATION MATRIX"].EditOpzione) &&
  !canI(auth["AUTHORIZATION MATRIX"].ReadOpzione)
    ? {}
    : {
        id: "usersDropdown",
        title: <FormattedMessage id="Users" />,
        type: "dropdown",
        icon: <Icon.Users size={15} />,
        children: [
          auth != null &&
          !canI(auth["USERS LIST (all employees?)"].EditOpzione) &&
          !canI(auth["USERS LIST (all employees?)"].ReadOpzione)
            ? {}
            : {
                id: "usersList",
                title: <FormattedMessage id="Users List" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/users",
              },
          auth != null &&
          !canI(auth["USERS ROLE"].EditOpzione) &&
          !canI(auth["USERS ROLE"].ReadOpzione)
            ? {}
            : {
                id: "usersRoles",
                title: <FormattedMessage id="Users Roles" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/user-levels",
              },
          auth != null &&
          !canI(auth["AUTHORIZATION MATRIX"].EditOpzione) &&
          !canI(auth["AUTHORIZATION MATRIX"].ReadOpzione)
            ? {}
            : {
                id: "authorization",
                title: <FormattedMessage id="Authorization Matrix" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: token != null ? "/authorization" : "/user/create",
              },
        ],
      },
  auth != null &&
  !canI(auth["SMS COMPANIES"].EditOpzione) &&
  !canI(auth["SMS COMPANIES"].ReadOpzione) &&
  !canI(auth["REGIONS"].EditOpzione) &&
  !canI(auth["REGIONS"].ReadOpzione) &&
  !canI(auth["CENTER OF EXCELLENCES"].EditOpzione) &&
  !canI(auth["CENTER OF EXCELLENCES"].ReadOpzione) &&
  !canI(auth["CENTER OF COMPETENCIES"].EditOpzione) &&
  !canI(auth["CENTER OF COMPETENCIES"].ReadOpzione) &&
  !canI(auth["PRODUCTS"].EditOpzione) &&
  !canI(auth["PRODUCTS"].ReadOpzione) &&
  !canI(auth["DEPARTMENTS"].EditOpzione) &&
  !canI(auth["DEPARTMENTS"].ReadOpzione) &&
  !canI(auth["INTERNAL CLUSTERS"].EditOpzione) &&
  !canI(auth["INTERNAL CLUSTERS"].ReadOpzione) &&
  !canI(auth["INTERNAL COSTS"].EditOpzione) &&
  !canI(auth["INTERNAL COSTS"].ReadOpzione)
    ? {}
    : {
        id: "listsDropdown",
        title: <FormattedMessage id={customLabel.company} />,
        type: "dropdown",
        icon: <Icon.Tool size={15} />,
        children: [
          auth != null &&
          !canI(auth["SMS COMPANIES"].EditOpzione) &&
          !canI(auth["SMS COMPANIES"].ReadOpzione)
            ? {}
            : {
                id: "entities",
                title: <FormattedMessage id={customLabel.entities} />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/entities",
              },
          auth != null &&
          !canI(auth["REGIONS"].EditOpzione) &&
          !canI(auth["REGIONS"].ReadOpzione)
            ? {}
            : {
                id: "regions",
                title: <FormattedMessage id="Regions" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/regions",
              },
          auth != null &&
          !canI(auth["CENTER OF EXCELLENCES"].EditOpzione) &&
          !canI(auth["CENTER OF EXCELLENCES"].ReadOpzione)
            ? {}
            : {
                id: "businessUnits",
                title: <FormattedMessage id={customLabel.coe} />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/center-of-excellences",
              },
          auth != null &&
          !canI(auth["CENTER OF COMPETENCIES"].EditOpzione) &&
          !canI(auth["CENTER OF COMPETENCIES"].ReadOpzione)
            ? {}
            : {
                id: "competences",
                title: <FormattedMessage id={customLabel.coc} />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/center-of-competences",
              },
          auth != null &&
          !canI(auth["PRODUCTS"].EditOpzione) &&
          !canI(auth["PRODUCTS"].ReadOpzione)
            ? {}
            : {
                id: "products",
                title: <FormattedMessage id="Products" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/prodotti",
                // navLink: "#",
              },
          auth != null &&
          !canI(auth["DEPARTMENTS"].EditOpzione) &&
          !canI(auth["DEPARTMENTS"].ReadOpzione)
            ? {}
            : {
                id: "departments",
                title: <FormattedMessage id="Departments" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/departments",
              },
          auth != null &&
          !canI(auth["INTERNAL CLUSTERS"].EditOpzione) &&
          !canI(auth["INTERNAL CLUSTERS"].ReadOpzione)
            ? {}
            : {
                id: "macroCat",
                title: <FormattedMessage id={customLabel.clusters} />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/macro-categories",
              },
          auth != null &&
          !canI(auth["INTERNAL COSTS"].EditOpzione) &&
          !canI(auth["INTERNAL COSTS"].ReadOpzione)
            ? {}
            : {
                id: "purchaseOrdersInt",
                title: <FormattedMessage id="Internal Costs" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/purchase-orders/int",
              },
        ],
      },
  auth != null &&
  !canI(auth["CUSTOMERS"].EditOpzione) &&
  !canI(auth["CUSTOMERS"].ReadOpzione) &&
  !canI(auth["CONTRACTORS"].EditOpzione) &&
  !canI(auth["CONTRACTORS"].ReadOpzione) &&
  !canI(auth["SITE PERSONNEL"].EditOpzione) &&
  !canI(auth["SITE PERSONNEL"].ReadOpzione) &&
  !canI(auth["QUALIFICATIONS (all sub-menu)"].EditOpzione) &&
  !canI(auth["QUALIFICATIONS (all sub-menu)"].ReadOpzione)
    ? {}
    : {
        id: "masterData",
        title: <FormattedMessage id="Master Data" />,
        type: "dropdown",
        icon: <Icon.Database size={15} />,
        children: [
          auth != null &&
          !canI(auth["CUSTOMERS"].EditOpzione) &&
          !canI(auth["CUSTOMERS"].ReadOpzione)
            ? {}
            : {
                id: "clients",
                title: <FormattedMessage id="Customers" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/clients",
              },
          auth != null &&
          !canI(auth["CONTRACTORS"].EditOpzione) &&
          !canI(auth["CONTRACTORS"].ReadOpzione)
            ? {}
            : {
                id: "personnelSuppliers",
                title: <FormattedMessage id="Contractors" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/personale-suppliers",
              },
          auth != null &&
          !canI(auth["SITE PERSONNEL"].EditOpzione) &&
          !canI(auth["SITE PERSONNEL"].ReadOpzione)
            ? {}
            : {
                id: "sitePersonnel",
                title: <FormattedMessage id="Site Personnel" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/personale",
              },
          auth != null &&
          !canI(auth["QUALIFICATIONS (all sub-menu)"].EditOpzione) &&
          !canI(auth["QUALIFICATIONS (all sub-menu)"].ReadOpzione)
            ? {}
            : {
                id: "qualificationsDropdown",
                title: <FormattedMessage id="Qualifications" />,
                type: "dropdown",
                icon: <Icon.Database size={15} />,
                children: [
                  {
                    id: "qualificationGroups",
                    title: <FormattedMessage id="Qualification Groups" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/raggruppamenti",
                  },
                  {
                    id: "qualifications",
                    title: <FormattedMessage id="Qualification" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/qualifiche",
                  },
                ],
              },
        ],
      },
  auth != null &&
  !canI(auth["JOBS (List)"].EditOpzione) &&
  !canI(auth["JOBS (List)"].ReadOpzione) &&
  !canI(auth["JOBS SUPERVISOR"].EditOpzione) &&
  !canI(auth["JOBS SUPERVISOR"].ReadOpzione) &&
  !canI(auth["JOBS OPTION SET-UP (all sub-menu)"].EditOpzione) &&
  !canI(auth["JOBS OPTION SET-UP (all sub-menu)"].ReadOpzione)
    ? {}
    : {
        id: "jobsDropdown",
        title: <FormattedMessage id="Jobs" />,
        type: "dropdown",
        icon: <Icon.BookOpen size={15} />,
        children: [
          auth != null &&
          !canI(auth["JOBS (List)"].EditOpzione) &&
          !canI(auth["JOBS (List)"].ReadOpzione)
            ? {}
            : {
                id: "jobs",
                title: <FormattedMessage id="Jobs" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/jobs",
              },
          auth != null &&
          !canI(auth["JOBS SUPERVISOR"].EditOpzione) &&
          !canI(auth["JOBS SUPERVISOR"].ReadOpzione)
            ? {}
            : {
                id: "purchaseOrdersExt",
                title: <FormattedMessage id="Purchase Orders" />,
                type: "item",
                //        icon: <Icon.DollarSign size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/purchase-orders/ext",
              },
          auth != null &&
          !canI(auth["PURCHASE ORDERS"].EditOpzione) &&
          !canI(auth["PURCHASE ORDERS"].ReadOpzione) &&
          !canI(auth["MAN/DAYS GRAPHS"].EditOpzione) &&
          !canI(auth["MAN/DAYS GRAPHS"].ReadOpzione) &&
          !canI(auth["JOB WORKLOAD"].EditOpzione) &&
          !canI(auth["JOB WORKLOAD"].ReadOpzione) &&
          !canI(auth["PERSONNEL WORKLOAD"].EditOpzione) &&
          !canI(auth["PERSONNEL WORKLOAD"].ReadOpzione) &&
          !canI(auth["QUALIFICATION PLANNING"].EditOpzione) &&
          !canI(auth["QUALIFICATION PLANNING"].ReadOpzione) &&
          !canI(auth["MAN/DAY DETAILS"].EditOpzione) &&
          !canI(auth["MAN/DAY DETAILS"].ReadOpzione) &&
          !canI(auth["PHASE CATEGORY GRAPH"].EditOpzione) &&
          !canI(auth["PHASE CATEGORY GRAPH"].ReadOpzione) &&
          !canI(auth["CASH REPORTS (all sub-menu)"].EditOpzione) &&
          !canI(auth["CASH REPORTS (all sub-menu)"].ReadOpzione)
            ? {}
            : {
                id: "jobsDataOptions",
                title: <FormattedMessage id="Jobs Options Set-up" />,
                type: "dropdown",
                icon: <Icon.Settings size={15} />,
                children: [
                  /* {
            id: "servicesToBeInvoiced",
            title: <FormattedMessage id="Contractual Services" />,
            type: "item",
            icon: <Icon.List size={15} />,
            permissions: ["admin", "editor"],
            navLink: "/inv-services",
          }, */
                  {
                    id: "calendars",
                    title: <FormattedMessage id="Calendars" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/calendars",
                  },
                  {
                    id: "rotations",
                    title: <FormattedMessage id="Rotations" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/rotations",
                  },
                  {
                    id: "visaTypes",
                    title: <FormattedMessage id="VISA" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/visa-types",
                  },
                  {
                    id: "tipoSpese",
                    title: <FormattedMessage id="Expenses Type" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/tipo-spese",
                  },
                  {
                    id: "currencies",
                    title: <FormattedMessage id="Currencies" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/valute",
                  },
                  {
                    id: "phaseType",
                    title: <FormattedMessage id="Phase Type" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/tipi-fase",
                  },
                ],
              },
        ],
      },
  //  auth != null
  //  && !canI(auth['PURCHASE ORDERS'].EditOpzione)
  //  && !canI(auth['PURCHASE ORDERS'].ReadOpzione) ?
  //  {}
  //  :
  //  {
  //    id: "purchaseOrdersExt",
  //    title: <FormattedMessage id="Purchase Orders" />,
  //    type: "item",
  //    icon: <Icon.DollarSign size={15} />,
  //    permissions: ["admin", "editor"],
  //    navLink: "/purchase-orders/ext",
  //  },

  auth != null &&
  !canI(auth["WORLD WIDE SITE"].EditOpzione) &&
  !canI(auth["WORLD WIDE SITE"].ReadOpzione) &&
  !canI(auth["DASHBOARD"].EditOpzione) &&
  !canI(auth["DASHBOARD"].ReadOpzione)
    ? {}
    : {
        id: "invoicesDropdown",
        title: <FormattedMessage id="Invoices" />,
        type: "dropdown",
        icon: <Icon.DollarSign size={15} />,
        children: [
          auth != null &&
          !canI(auth["WORLD WIDE SITE"].EditOpzione) &&
          !canI(auth["WORLD WIDE SITE"].ReadOpzione)
            ? {}
            : {
                id: "invoicesList",
                title: <FormattedMessage id="Invoices list" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/invoices",
              },
          process.env.REACT_APP_PRE_INVOICES_MODULE === "true" && {
            id: "preInvoices",
            title: <FormattedMessage id="Pre-Invoices" />,
            type: "item",
            // icon: <Icon.List size={15} />,
            permissions: ["admin", "editor"],
            navLink: "/pre-invoices",
          },
        ],
      },
  auth != null &&
  !canI(auth["MAN/DAYS GRAPHS"].EditOpzione) &&
  !canI(auth["MAN/DAYS GRAPHS"].ReadOpzione) &&
  !canI(auth["JOB WORKLOAD"].EditOpzione) &&
  !canI(auth["JOB WORKLOAD"].ReadOpzione) &&
  !canI(auth["PERSONNEL WORKLOAD"].EditOpzione) &&
  !canI(auth["PERSONNEL WORKLOAD"].ReadOpzione) &&
  !canI(auth["QUALIFICATION PLANNING"].EditOpzione) &&
  !canI(auth["QUALIFICATION PLANNING"].ReadOpzione) &&
  !canI(auth["MAN/DAY DETAILS"].EditOpzione) &&
  !canI(auth["MAN/DAY DETAILS"].ReadOpzione) &&
  !canI(auth["PHASE CATEGORY GRAPH"].EditOpzione) &&
  !canI(auth["PHASE CATEGORY GRAPH"].ReadOpzione) &&
  !canI(auth["CASH REPORTS (all sub-menu)"].EditOpzione) &&
  !canI(auth["CASH REPORTS (all sub-menu)"].ReadOpzione)
    ? {}
    : {
        id: "reports",
        title: <FormattedMessage id="Reports" />,
        type: "dropdown",
        icon: <Icon.BarChart2 size={15} />,
        children: [
          auth != null &&
          !canI(auth["MAN/DAYS GRAPHS"].EditOpzione) &&
          !canI(auth["MAN/DAYS GRAPHS"].ReadOpzione)
            ? {}
            : {
                id: "totalsGraph",
                title: <FormattedMessage id="Man/Days Graphs" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/totalgraphs",
              },
          auth != null &&
          !canI(auth["JOB WORKLOAD"].EditOpzione) &&
          !canI(auth["JOB WORKLOAD"].ReadOpzione)
            ? {}
            : {
                id: "jobWorkload",
                title: <FormattedMessage id="Job Workload" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/workload",
              },
          auth != null &&
          !canI(auth["PERSONNEL WORKLOAD"].EditOpzione) &&
          !canI(auth["PERSONNEL WORKLOAD"].ReadOpzione)
            ? {}
            : {
                id: "JobsSupervisor",
                title: <FormattedMessage id="Jobs Supervisor" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/jobs-supervisor",
              },
          auth != null &&
          !canI(auth["JOBS OPTION SET-UP (all sub-menu)"].EditOpzione) &&
          !canI(auth["JOBS OPTION SET-UP (all sub-menu)"].ReadOpzione)
            ? {}
            : {
                id: "personnelWorkload",
                title: <FormattedMessage id="Personnel Workload" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/personnelworkload",
              },
          auth != null &&
          !canI(auth["QUALIFICATION PLANNING"].EditOpzione) &&
          !canI(auth["QUALIFICATION PLANNING"].ReadOpzione)
            ? {}
            : {
                id: "qualificationPlanning",
                title: <FormattedMessage id="Qualification Planning" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/qualificationPlanning",
              },
          auth != null &&
          !canI(auth["MAN/DAY DETAILS"].EditOpzione) &&
          !canI(auth["MAN/DAY DETAILS"].ReadOpzione)
            ? {}
            : {
                id: "manDayDetails",
                title: <FormattedMessage id="Man/Day Details" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/manDayDetails",
              },
          auth != null &&
          !canI(auth["PHASE CATEGORY GRAPH"].EditOpzione) &&
          !canI(auth["PHASE CATEGORY GRAPH"].ReadOpzione)
            ? {}
            : {
                id: "phaseCategoryGraph",
                title: <FormattedMessage id="Phase Category Graph" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/phaseCategoryGraph",
              },
          auth != null &&
          !canI(auth["CASH REPORTS (all sub-menu)"].EditOpzione) &&
          !canI(auth["CASH REPORTS (all sub-menu)"].ReadOpzione)
            ? {}
            : {
                id: "cashReports",
                title: <FormattedMessage id="Cash Reports" />,
                type: "dropdown",
                icon: <Icon.BarChart2 size={15} />,
                children: [
                  {
                    id: "cashOutReports",
                    title: <FormattedMessage id="Costs Details" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/reports/cashOut",
                  },
                  {
                    id: "cashInReports",
                    title: <FormattedMessage id="Incomes Details" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/reports/cashIn",
                  },
                  {
                    id: "cashFlow",
                    title: <FormattedMessage id="Balance Sheet" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/reports/cashFlow",
                  },
                  {
                    id: "prjYearValues",
                    title: <FormattedMessage id="Project Year Values" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/reports/prjYearValues",
                  },
                  /*{

                        id: "cashFlowDetail",
            title: <FormattedMessage id="Balance Sheet Detail" />,
            type: "item",
            // icon: <Icon.List size={15} />,
            permissions: ["admin", "editor"],
            navLink: "/reports/cashFlowDetail",
          }, */
                  /* {
            id: "cashFlowCostType",
            title: <FormattedMessage id="Cash Flow Cost Type" />,
            type: "item",
            icon: <Icon.List size={15} />,
            permissions: ["admin", "editor"],
            navLink: "/reports/cashFlowCostType",
          }, */
                ],
              },
        ],
      },
];

export default horizontalMenuConfig;
